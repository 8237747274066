<template>
  <div>
    <v-app-bar app color="mainBackground" height="100" elevate-on-scroll>
      <router-link to="/">
        <v-avatar class="mr-3" color="mainBackground" size="100" tile>
          <v-img v-if="$vuetify.theme.dark" contain max-height="85%"  src="@/assets/logo/TTG_logo_Reverse_horz.png"></v-img>
          <v-img v-if="!$vuetify.theme.dark" contain max-height="85%" src="@/assets/logo/TTG_logo_Black_horz.png"></v-img>
          <!-- <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img> -->
        </v-avatar>
      </router-link>

      <v-toolbar-title v-if="userIsAuthed" class="app-bar-title" :class="[$vuetify.breakpoint.smAndDown ? 'text-h8 gotham-font' : 'text-h5 gotham-font']">
        Customer Portal
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-app-bar-nav-icon class="mainRed--text" v-if="userIsAuthed" @click.stop="drawer = !drawer" /> -->

      <v-btn class="text-h gotham-font" v-if="!userIsAuthed && notSignInRoute" :to="{ name: 'SignIn' }" text>Sign In</v-btn>
      <v-btn v-if="userIsAuthed && $route.name !== 'app.dashboard'" :to="{ name: 'app.dashboard' }" text color="mainRed">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <div v-if="userIsAuthed">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" color="mainRed">
              <v-icon>mdi-account</v-icon>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader style="height: 30px;">User Account</v-subheader>
            <v-list-item two-line>
              <v-list-item-avatar style="margin-left: -7px; margin-right: 24px">
                <v-icon size="25">mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ fullName }}</v-list-item-title>
                <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <!-- <v-list-item :disabled="$route.name === 'app.dashboard'" :to="{ name: 'app.dashboard' }">
              <v-list-item-title>Registration Dashboard</v-list-item-title>
            </v-list-item> -->
            <!-- <v-list-item :to="{ name: 'app.documentsNeeded' }">
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>What Documents Do I Need</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'app.faq' }">
              <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Frequently Asked Questions</v-list-item-title>
            </v-list-item> -->

            <v-list-item :to="{ name: 'app.contactUs' }">
              <v-list-item-icon>
                <v-icon>mdi-email-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contact Us</v-list-item-title>
            </v-list-item>

            <v-list-item @click.stop.prevent style="padding: 0;">
              <v-list-item-title style="padding-left: 15px">
                <v-switch v-model="darkMode">
                  <div slot="label" style="margin-left: 12px">Dark Mode</div>
                </v-switch>
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="logOut">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="text-caption justify-end"> version {{ appVersion }} </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import store from '../../store';

export default {
  name: 'TopMenu',
  data: () => ({
    drawer: null,
    group: null,
    hints: false,
    menu: false,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    userIsAuthed: function() {
      return this.$store.state.app.userData.isAuthed;
    },
    userEmail: function() {
      return this.$store.state.app.userData.email;
    },
    fullName: function() {
      return this.$store.state.app.userData.firstName + ' ' + this.$store.state.app.userData.lastName;
    },
    notSignInRoute() {
      return this.$route.name !== 'SignIn';
    },
    appVersion: function() {
      return VUE_APP_VERSION;
    },
    darkMode: {
      get() {
        return this.$store.state.app.userData.darkModeOn;
      },
      set(value) {
        this.$store.commit('app/toggleDarkMode', '', { root: true });
      },
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('app/logUserOut');
    },
  },
};
</script>

<style scoped></style>
